import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import logo from "../../assets/images/footer_logo.png";
import logo1 from "../../assets/images/logo.png";

const Main = ({ navbar }) => {
  const location = useLocation();
  const path = location.pathname;

  const [show, setShow] = useState();
  const [show1, setShow1] = useState();
  const [show2, setShow2] = useState();
  const [mobile, setmobile] = useState();

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <>
      {path === "/" ? (
        <>
          {navbar.n2 && (
            <header>
              <div className="container">
                <nav className="navbar navbar-expand-lg">
                  <Link to="/" className="navbar-brand">
                    <img src={logo1} alt="image" />
                  </Link>
                 
                  <div className="collapse navbar-collapse">
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item">
                        <Link
                          onClick={() => {
                            // if apple device then open app store else open play store
                            if (/(android)/i.test(navigator.userAgent)) {
                              window.location.href =
                                "https://ibac.onelink.me/KFb4/je91070f";
                              return;
                            }
                            window.location.href =
                              "https://ibac.onelink.me/KFb4/je91070fe";
                          }}
                          className="nav-link dark_btn"
                        >
                          Descarcă gratis
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </header>
          )}
        </>
      ) : null}
    
    </>
  );
};

export default Main;
