import { HashLink as Link } from "react-router-hash-link";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import OwlCarousel from "react-owl-carousel";

import anim from "../../assets/images/anim_line.png";
import blueapp from "../../assets/images/appstore_blue.png";
import whiteapp from "../../assets/images/appstore_white.png";
import blue from "../../assets/images/googleplay_blue.png";
import white from "../../assets/images/googleplay_white.png";
import msg from "../../assets/images/message_icon.png";
import shield from "../../assets/images/shield_icon.png";
import frame from "../../assets/images/mobile_frame_svg.svg";

const Main = ({ setfooter, setnavbar, setbrdcum }) => {
  const [deepLinkParam, setDeepLinkParam] = useState("");
  const isDesktop = () => {
    return !isAndroid() && !isIos();
  };

  const isAndroid = () => {
    const ua = navigator.userAgent;
    return /Android/i.test(ua);
  };

  const isIos = () => {
    const ua = navigator.userAgent;
    return /iPhone|iPad|iPod/i.test(ua);
  };

  const appScheme = "ibacorbit";

  const openApp = () => {
    if (isDesktop()) {
      return false;
    }

    const ua = navigator.userAgent;
    const isIos = /iPhone|iPad|iPod/i.test(ua);

    // get screen name ?screen=NAME
    const urlParams = new URLSearchParams(window.location.search);
    const screen = urlParams.get("screen");

    // get the rest of params as object key value
    const params = {};

    urlParams.forEach((value, key) => {
      if (key !== "screen") {
        params[key] = value;
      }
    });

    // build the url
    let url = appScheme + "://";

    if (screen) {
      url += screen;
    }

    if (Object.keys(params).length > 0) {
      url += "?";

      Object.keys(params).forEach((key, index) => {
        url += key + "=" + params[key];

        if (index < Object.keys(params).length - 1) {
          url += "&";
        }
      });
    }

    try {
      // only if after ibacorbit:// there is something
      if (url.length > 13) {
        setDeepLinkParam(url);
      }
    } catch (e) {}
  };

  useEffect(() => {
    openApp();
  }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    setfooter({ f1: true });
    setnavbar({ n2: true });
    setbrdcum({ b1: true });
    localStorage.setItem("navbar", "home");
  }, []);

  const frmae_slider = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1500,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  if (deepLinkParam) {
    return (
      <div
        style={{
          position: "fixed",
          display: "flex",
          zIndex: 9999,
          height: "100vh",
          background: "#ffffff",
          width: "100vw",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          backdropFilter: "blur(10px)",
          padding: "0 20px",
        }}
      >
        <a
          href={deepLinkParam}
          style={{
            color: "white",
            backgroundColor: "#007bff",
            borderColor: "#007bff",
            padding: ".375rem 1.5rem",
            borderRadius: ".55rem",
          }}
        >
          Continuă în aplicație
        </a>

        <p
          onClick={() => {
            setDeepLinkParam("");
          }}
          style={{
            color: "#1b1b1b",
            marginTop: "16px",
            borderColor: "#007bff",
            padding: ".375rem 1.5rem",
            borderRadius: ".55rem",
          }}
        >
          Vezi site-ul
        </p>
      </div>
    );
  }

  return (
    <>
      <section className="banner_section home-banner">
        <div className="container">
          <div className="anim_line">
            <span>
              <img src={anim} alt="anim_line" />
            </span>
            <span>
              <img src={anim} alt="anim_line" />
            </span>
            <span>
              <img src={anim} alt="anim_line" />
            </span>
            <span>
              <img src={anim} alt="anim_line" />
            </span>
            <span>
              <img src={anim} alt="anim_line" />
            </span>
            <span>
              <img src={anim} alt="anim_line" />
            </span>
            <span>
              <img src={anim} alt="anim_line" />
            </span>
            <span>
              <img src={anim} alt="anim_line" />
            </span>
            <span>
              <img src={anim} alt="anim_line" />
            </span>
          </div>
          <div className="row">
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="banner_text">
                <h1>
                  Pregătește-te pentru <span>examenul de bacalaureat.</span>
                </h1>
                <p>
                  Te ajutăm să înveți mai ușor și mai eficient. Îți oferim un
                  mediu de învățare interactiv, cu teste și explicații, pentru
                  ca tu să fii pregătit pentru examenul de bacalaureat.
                </p>
              </div>
              <ul className="app_btn">
                <li>
                  <a href="https://apps.apple.com/ro/app/ibac-bacul-in-romania/id1563989886?platform=iphone">
                    <img className="blue_img" src={blueapp} alt="image" />
                    <img className="white_img" src={whiteapp} alt="image" />
                  </a>
                </li>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=com.oliu.ibac">
                    <img className="blue_img" src={blue} alt="image" />
                    <img className="white_img" src={white} alt="image" />
                  </a>
                </li>
              </ul>
              <div className="used_app">
                <ul>
                  <li>
                    <img src="https://i.imgur.com/xMFQYhh.png" alt="image" />
                  </li>
                  <li>
                    <img src="https://i.imgur.com/o4wLFXv.png" alt="image" />
                  </li>
                  <li>
                    <img src="https://i.imgur.com/3aNnHuR.png" alt="image" />
                  </li>
                  <li>
                    <img src="https://i.imgur.com/kMSPuS0.png" alt="image" />
                  </li>
                </ul>
                <p>
                  100K + <br /> utilizatori
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-in"
              data-aos-duration="1500"
            >
              <div className="banner_slider">
                <div className="left_icon">
                  <img src={msg} alt="image" />
                </div>
                <div className="right_icon">
                  <img src={shield} alt="image" />
                </div>
                <OwlCarousel
                  id="frmae_slider"
                  className="owl-carousel owl-theme owl-loaded owl-drag"
                  {...frmae_slider}
                >
                  <div className="item">
                    <div className="slider_img">
                      <img
                        src={"https://i.imgur.com/xbTw03s.png"}
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_img">
                      <img
                        src={"https://i.imgur.com/NpLdnVV.png"}
                        alt="image"
                      />
                    </div>
                  </div>

                  <div className="item">
                    <div className="slider_img">
                      <img
                        src={"https://i.imgur.com/0lmW2GA.png"}
                        alt="image"
                      />
                    </div>
                  </div>

                  <div className="item">
                    <div className="slider_img">
                      <img
                        src={"https://i.imgur.com/GQBQaOA.png"}
                        alt="image"
                      />
                    </div>
                  </div>
                </OwlCarousel>
                <div className="slider_frame">
                  <img src={frame} alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Trusted />
        <Features />
        <AboutApp />
        <Design />
        <Work />
        <Testimonial />
        <Pricing />
        <Faq/>
        <Interface/>
        <Download />
        <Story/> */}
    </>
  );
};

export default Main;
