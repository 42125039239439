import React , { useEffect, useState } from 'react'
import { Route, Routes , useLocation} from 'react-router-dom'
import Navbar from '../component/Navbar/Main'
import Footer from '../component/Footer/Main'
import Home from '../component/Home/Main'
import '../index.css'
const Routing = () => {

    const [homepage, sethomepage] = useState(false)
    const location = useLocation();

    useEffect(() => {
      if (location.pathname === "/sign-in" || location.pathname === "/sign-up"  ){
        sethomepage(false)
      }else{
        sethomepage(true)
      }
    }, [location])

    const [footerpage, setfooterpage] = useState(false)
    useEffect(() => {
      if (location.pathname === "/sign-in" || location.pathname === "/sign-up" ){
        setfooterpage(false)
      }else{
        setfooterpage(true)
      }
    }, [location])

    const [footer , setfooter] = useState({f1 : true})
    const [navbar , setnavbar] = useState({n1 : true})
    const [brdcum , setbrdcum] = useState({n1 : true})
    
    return (
        <>
            {homepage && <Navbar navbar = {navbar}/>}
            <Routes>
                <Route path="/" element={<Home setfooter = {setfooter} setnavbar = {setnavbar} setbrdcum = {setbrdcum}/>} />
                {/* <Route path="/about" element={< AboutUs setnavbar = {setnavbar} brdcum = {brdcum}/>} /> */}
                {/* <Route path="/review" element={< Review setnavbar = {setnavbar} brdcum = {brdcum} />} /> */}
                {/* <Route path="/contact" element={< Contact brdcum = {brdcum}/>} /> */}
                {/* <Route path="/faq" element={< Faq brdcum = {brdcum} />} /> */}
                {/* <Route path="/sign-in" element={< SignIn />} /> */}
                {/* <Route path="/sign-up" element={< SignUp />} /> */}
                {/* <Route path="/pricing" element={< Pricing brdcum = {brdcum}/>} /> */}
                {/* <Route path="/blog" element={< Blog brdcum = {brdcum}/>} /> */}
                {/* <Route path="/blog-single" element={< BlogSingle brdcum = {brdcum}/>} /> */}
                {/* <Route path="/home-dark" element={< HomeDark setfooter = {setfooter} setnavbar = {setnavbar} setbrdcum = {setbrdcum}/>} /> */}
                {/* <Route path="/home-wave" element={< HomeWave setfooter = {setfooter} setnavbar = {setnavbar} setbrdcum = {setbrdcum}/>} /> */}
                {/* <Route path="/home-gredient" element={< HomeGredient setfooter = {setfooter} setnavbar = {setnavbar} setbrdcum = {setbrdcum}/>} /> */}
                {/* <Route path="/home-video" element={< HomeVideo setfooter = {setfooter} setnavbar = {setnavbar} setbrdcum = {setbrdcum}/>} /> */}
                {/* <Route path="/home-video2" element={< HomeVideoTwo setfooter = {setfooter} setnavbar = {setnavbar} setbrdcum = {setbrdcum}/>} /> */}
            </Routes>
            {footerpage && <Footer footer = {footer}/>}
        </>
    )
}

export default Routing