import { Link } from "react-router-dom";
import React from "react";
import line from "../../assets/images/anim_line.png";
import blueapp from "../../assets/images/appstore_blue.png";
import blue from "../../assets/images/googleplay_blue.png";
import logo from "../../assets/images/footer_logo.png";
import top from "../../assets/images/go_top.png";

const Main = ({ footer }) => {
  return (
    <>
      <section className="newsletter_section">
        <div className="container">
          <div className="newsletter_box">
            <div
              className="section_title"
              data-aos="fade-in"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              <h2>Comunitatea RoBac</h2>
              <p>Peste 17.000 de elevi sunt deja alături de noi pe discord.</p>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                window.location.href = "https://discord.gg/robac";
              }}
              data-aos="fade-in"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              <div
                className="form-group"
                style={{
                  marginLeft: "auto",
                }}
              >
                <button
                  onClick={() => {
                    window.location.href = "https://discord.gg/robac";
                  }}
                  href="https://discord.gg/robac"
                  className="btn"
                >
                  Alătură-te
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      {footer.f1 && (
        <footer>
          <div className="top_footer" id="contact">
            <div className="anim_line dark_bg">
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
              <span>
                <img src={line} alt="anim_line" />
              </span>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="abt_side">
                    <div className="logo">
                      {" "}
                      <img src={logo} alt="image" />
                    </div>
                    <ul className="social_media">
                      <li>
                        <Link to="#">
                          <i className="icofont-facebook"></i>
                        </Link>
                      </li>

                      <li>
                        <Link to="#">
                          <i className="icofont-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-12">
                  <div className="legal">
                    <h3>Legal</h3>
                    <ul>
                      <li>
                        <p>IBAC SOFTWARE S.R.L</p>
                      </li>
                      <li>
                        <p>Argentina 25, 011754 Bucharest</p>
                      </li>
                      <li>
                        <p>+40735764791</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_footer">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p>© Copyrights 2022. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="go_top">
            <span>
              <img src={top} alt="image" />
            </span>
          </div>
        </footer>
      )}
    </>
  );
};

export default Main;
